// circle.js
import React from 'react';
import './circle.css';

function CircleProgress({ languages }) {
  return (
    <div className="circle-progress-container">
      {languages.map((languages, index) => {
        const circumference = 2 * Math.PI * 40;
        const progressLength = (languages.progress / 100) * circumference;

        return (
          <div key={index} className="circle-progress">
            <svg width="100" height="100">
              <circle className="circle-background" cx="50" cy="50" r="40" />
              <circle
                className="circle-bar"
                cx="50"
                cy="50"
                r="40"
                strokeDasharray={`${progressLength} ${circumference}`}
                strokeDashoffset="0"
                stroke={languages.color}
              />
              <text className="circle-text" x="50%" y="50%" textAnchor="middle" dy="0.3em">
                {languages.progress}%
              </text>
            </svg>
            <p className="circle-label">
              <span className="circle-label-name">{languages.name}</span> {/* Hide the name element */}
              {languages.label}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default CircleProgress;
