import React, { useState } from 'react';
import { BsFillMoonStarsFill, BsFillSunFill } from 'react-icons/bs';

function DayNightToggle({ toggleTheme }) {
  const [isDayMode, setIsDayMode] = useState(true);

  const toggleMode = () => {
    setIsDayMode(!isDayMode);
    toggleTheme(); // Call the toggleTheme callback
  };

  return (
    <div>
      {isDayMode ? (
        <BsFillMoonStarsFill
          className="icon" // Add the "icon" class name
          onClick={toggleMode}
          style={{ cursor: 'pointer', fontSize: '5em' }}
        />
      ) : (
        <BsFillSunFill
          className="icon" // Add the "icon" class name
          onClick={toggleMode}
          style={{ cursor: 'pointer', fontSize: '5em' }}
        />
      )}
    </div>
  );
}

export default DayNightToggle;
