import React, { useEffect, useState } from 'react';
import words from './typewriterwords'; // Import the words array

function Typewriter() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const textElement = document.querySelector('.typewriter-text');

    const currentWord = words[currentWordIndex];
    const currentText = isTyping
      ? currentWord.slice(0, currentCharIndex)
      : currentWord.slice(0, currentCharIndex + 1);

    textElement.textContent = currentText;

    const typingTimer = setTimeout(() => {
      if (isTyping) {
        if (currentCharIndex < currentWord.length) {
          setCurrentCharIndex(currentCharIndex + 1);
        } else {
          setIsTyping(false);
        }
      } else {
        if (currentCharIndex >= 0) {
          setCurrentCharIndex(currentCharIndex - 1);
        } else {
          setIsTyping(true);
          setCurrentCharIndex(0);
          setCurrentWordIndex((currentWordIndex + 1) % words.length);
        }
      }
    }, isTyping ? 100 : 50); // Typing/Erasing speed (adjust as needed)

    return () => {
      clearTimeout(typingTimer);
    };
  }, [currentWordIndex, currentCharIndex, isTyping]);

  return (
    <div className="typewriter-container">
      <div className="typewriter">
        <p className="typewriter-text custom-text-style"> </p>
      </div>
    </div>
  );
}

export default Typewriter;
