// App.js

import React, { useState } from "react";
import { Socialicons } from "./components/Social/socialiconsbar";
import Navbar from "./components/Nav/Navbar";
import DayNightToggle from "./components/DayNight/daynighttoggle";
import Hero from "./components/Hero/hero";
import About from "./components/About/about";
import Timeline from "./components/Timeline/Timeline";
// import MetaMaskLogin from "./components/MetaMask/MetaMaskLogin";
// import CapturedInfo from "./components/MetaMask/CapturedInfo"; // Import CapturedInfo
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import React Router components
import "./App.css";

export const toggleTheme = (theme, setTheme) => {
  setTheme(theme === "light" ? "dark" : "light");
};

function App() {
  const [theme, setTheme] = useState("dark");

  // const scrollToSection = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   if (section) {
  //     section.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    // <Router>
    <div className={`App ${theme}`}>
      <Navbar />
      {/* <Routes>
          <Route
            path="/"
            element={<MetaMaskLogin theme={theme} setTheme={setTheme} />} // Pass theme and setTheme as props
          />
          <Route path="/captured-info" element={<CapturedInfo />} />
        </Routes> */}

      <section id="Home">
        <Socialicons />
        <Hero theme={theme} setTheme={setTheme} />
      </section>
      <section id="Timeline">
        <Timeline />
      </section>
      <section id="Skills">
        <About />
      </section>
    </div>
    // </Router>
  );
}

export default App;
export { DayNightToggle };
