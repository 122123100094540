// Navbar.js
import React from 'react';
import './Navbar.css';
import { BiHomeAlt2, BiTargetLock } from "react-icons/bi"
import {TbRoad} from "react-icons/tb";
// import {GiBroadsword} from "react-icons/gi";

const Navbar = () => {
    return (
        <div className="navbar">
            <ul>
                <li>
                    <a href="#Home" className="icon-link">
                        <div className="icon-container">
                            <div className="nav-icon">
                                <BiHomeAlt2 />
                            </div>
                            <span className="icon-text">Home</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#Timeline" className="icon-link">
                        <div className="icon-container">
                            <div className="nav-icon">
                                <TbRoad />
                            </div>
                            <span className="icon-text">Timeline</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#Skills" className="icon-link">
                        <div className="icon-container">
                            <div className="nav-icon">
                                <BiTargetLock />
                            </div>
                            <span className="icon-text">Skills</span>
                        </div>
                    </a>
                </li>
                {/* <li>
                    <a href="#" className="icon-link">
                        <div className="icon-container">
                            <div className="nav-icon">
                                <GiBroadsword />
                            </div>
                            <span className="icon-text">PlaceHolder</span>
                        </div>
                    </a>
                </li> */}
            </ul>
        </div>
    );
};

export default Navbar;
