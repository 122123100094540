// Hero.js
import React from "react";
import "./hero.css";
import Typewriter from "./typewrite";
import { DayNightToggle, toggleTheme } from "../../App"; // Import the DayNightToggle component and toggleTheme

function Hero({ theme, setTheme }) {
  // Receive theme and setTheme as props
  return (
    <div className="hero">
      {/* Text for hero */}
      <div className="hero-text">
        <DayNightToggle toggleTheme={() => toggleTheme(theme, setTheme)} />{" "}
        {/* Pass the toggleTheme function to DayNightToggle */}
        <h1 className={theme}>Swaleh Abdulrehman</h1>
        <Typewriter />
        <p className={`theme herofooter`}>
          Only during the pursuit of greatness, do we find who we were created
          to be.
        </p>
      </div>

      {/* Hero Image */}
      <div className="hero-image"></div>
    </div>
  );
}

export default Hero;
