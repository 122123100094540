// About.js
import React from 'react';
import './about.css';
import Skills from './skills';

function About() {
  return (
    <div className="about">
      <div className="centered-text">
        <Skills />
      </div>
    </div>
  );
}

export default About;
