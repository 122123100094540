// socialprofils.js
const socialprofils = {
  github: "https://github.com/EthDevelopment",
  facebook: "https://www.facebook.com",
  linkedin: "https://linkedin.com/in/swaleh",
  twitter: "https://twitter.com/",
  youtube: "https://www.youtube.com/",
  tiktok: "https://www.tiktok.com/",
  instagram: "https://www.instagram.com/",
};

export default socialprofils;
