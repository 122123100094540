const words = [
  "We create our own luck!",
  "Artist",
  "Creative",
  "Programmer",
  "Blockchain Engineer",
  "Man",
  "Athlete",
  "Free Thinker",
  "1 of 1",
  "Dreamer",
  "Stoic",
  "Orator",
  "Bitcoin Maxi",
  "Innovator",
  "Entrepreneur",
  "Engineering on Earth",
];

export default words;
