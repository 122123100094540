import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"; // Import the styles
import "./Timeline.css";

function Timeline() {
  return (
    <div>
      <div className="timeline-header">
        <h1>Timeline</h1>
      </div>
      <div className="timeline-container">
        <VerticalTimeline className="vertical-temline">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2024 - Completion"
            iconClassName="custom-icon-class"
          >
            <h3 className="vertical-timeline-element-title">Earth</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Developer @Swaleh
            </h4>
            <h4 className="vertical-timeline-element-text">Blockchain</h4>
            <h4 className="vertical-timeline-element-text">
              {"Project: Shhh it's a secret ;)"}
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2023 - March 2024"
            iconClassName="custom-icon-class"
          >
            <h3 className="vertical-timeline-element-title">Manchester, UK</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Software Engineer II @S&P Global
            </h4>
            <h4 className="vertical-timeline-element-text">
              React, Vite, JavaScript, Agile, Figma, Miro
            </h4>
            <h4 className="vertical-timeline-element-text">
              Project: Portfolio Management <br></br>Role: Frontend developer
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2022 - September 2023"
            iconClassName="custom-icon-class" // Add a custom class for the icon
          >
            <h3 className="vertical-timeline-element-title">Manchester, UK</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Software Engineer I @S&P Global
            </h4>
            <h4 className="vertical-timeline-element-text">
              Devops, AWS, C#, Pipelines, React, Agile, Miro, Figma
            </h4>
            <h4 className="vertical-timeline-element-text">
              Project: Reference Architecture <br></br>Roles: Devops engineer,
              Scrum master, Backend dev
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021 - 2022"
            iconClassName="custom-icon-class" // Add a custom class for the icon
          >
            <h3 className="vertical-timeline-element-title">Remote</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Social Media Manager @LuxLifeFlex
            </h4>
            <p>
              Followers: 56,000
              <br />
              Peak Impressions: 17,000,000
              <br />
              Media Planning, Customer Engagement, Organic Growth{" "}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2022"
            iconClassName="custom-icon-class" // Add a custom class for the icon
          >
            <h3 className="vertical-timeline-element-title">Swansea, UK</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Computer Science BSC @Swansea University
            </h4>
            <p>
              Solidity, Figma, Miro, Java, Python, HTML/CSS, Haskell, Prolog,
              UX/UI{" "}
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;
