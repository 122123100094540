import React from "react";
import "./socialiconsbar.css";
import {
  FaGithub,
  FaTwitter,
  FaFacebookF,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import { BiLogoTiktok } from "react-icons/bi";
import socialprofils from "./content_option";

export const Socialicons = ({ theme, setTheme }) => {
  return (
    <div
      className={`stick_follow_icon ${
        theme === "light" ? "light-mode" : "dark-mode"
      }`}
    >
      <ul>
        {socialprofils.github && (
          <li>
            <a href={socialprofils.github}>
              <FaGithub className="icon" />
            </a>
          </li>
        )}
        {socialprofils.linkedin && (
          <li>
            <a href={socialprofils.linkedin}>
              <FaLinkedin className="icon" />
            </a>
          </li>
        )}
        {socialprofils.youtube && (
          <li>
            <a href={socialprofils.youtube}>
              <FaYoutube className="icon" />
            </a>
          </li>
        )}
        {socialprofils.instagram && (
          <li>
            <a href={socialprofils.instagram}>
              <FaInstagram className="icon" />
            </a>
          </li>
        )}
        {socialprofils.tiktok && (
          <li>
            <a href={socialprofils.tiktok}>
              <BiLogoTiktok className="icon" />
            </a>
          </li>
        )}
        {socialprofils.twitter && (
          <li>
            <a href={socialprofils.twitter}>
              <FaTwitter className="icon" />
            </a>
          </li>
        )}

        {socialprofils.facebook && (
          <li>
            <a href={socialprofils.facebook}>
              <FaFacebookF className="icon" />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
