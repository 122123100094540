// Skills.js
import React from 'react';
import CircleProgress from './circle'; // Import the Circle component

function Skills() {
    // Define an array of programming languages with progress values
    const technicalSkills = [
        { name: 'UX/UI Design', progress: 70 },
        { name: 'Graphic Design', progress: 70 },
        { name: 'Videography', progress: 45 },
        { name: 'Digital Marketing', progress: 70 },
        { name: 'Web Dev', progress: 65 },
        { name: 'E-Commerce', progress: 54 },
    ];

    // Define an array of personal skills with progress values
    const personalSkills = [
        { name: 'Presentation', progress: 80 },
        { name: 'Communication', progress: 90 },
        { name: 'Problem Solving', progress: 85 },
        { name: 'Social Media Management', progress: 85 },
        { name: 'Time Management', progress: 80 },
        { name: 'Leadership', progress: 70 },
    ];

    // Define interests with progress values
    const languages = [
        { name: 'Java', progress: 40 },
        { name: 'React', progress: 65 },
        { name: 'HTML/CSS', progress: 75 },
        { name: 'YAML', progress: 60 },
        { name: 'Solidity', progress: 40 },
        { name: 'C#', progress: 30 },
    ];

    return (
        <div className="skills">
            <h3>Skills</h3>
            <div className="skills-container">
                <div className="skills-section">
                    <h4>Technical </h4>
                    {technicalSkills.map((language, index) => (
                        <div className="skill" key={index}>
                            <p>{language.name}</p>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: `${language.progress}%` }}></div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="skills-section">
                    <h4>Personal </h4>
                    {personalSkills.map((skill, index) => (
                        <div className="skill" key={index}>
                            <p>{skill.name}</p>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: `${skill.progress}%` }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <h4>Programming languages</h4>
            <div className="circle-section center-section">
                {languages.map((languages, index) => (
                    <div className="skill" key={index}>
                        <CircleProgress languages={[languages]} /> {/* Use the updated CircleProgress component */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Skills;
